<template>
	<div class="shop">
		<div class="shop-count">
			<div class="shop-body">
				<!-- 菜单 -->
				<div class="shop-body-menu">
					<div class="shop-body-menu-left">产品分类：</div>
					<div class="box">
						<div class="shop-body-menu-right" :class="{ bg: index == isSize }"
							@click="clickMenu(index,item.productType)" v-for="(item,index) in productmenu" :key="index">
							<!-- <div :class="{ bg: index == isSize }"> -->
							{{item.name}}
							<!-- </div> -->
						</div>
					</div>
				</div>
			</div>
			<!-- 商品部分 -->
			<div class="body">
				<div class="body-list" v-for="(item,index) in afterChangeData" :key="index" @click="toPage(item.id)">
					<div class="body-list-img">
						<img :src="item.picUrl" />
					</div>
					<div class="body-list-font">
						<div class="body-list-font-title">{{item.title}}</div>
						<div class="body-list-font-count">{{item.intro}}</div>
					</div>
					<div class="body-list-show">
						<div class="body-list-show-price">￥{{item.price}}</div>
						<div class="body-list-show-deailt">查看详情</div>
					</div>
				</div>
			</div>
			<div class="shop-pagination">
				<el-pagination layout=" pager" :total="count" :page-size="pageNum"
				:current-page="currentPage"
					@current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				productmenu: [],
				productlist: [],
				condition: [], //条件
				routerId: 0,
				isSize: 0,

				pageNum: 12, // 每页显示数量
				count: 0, //  总共多少条数据
				currentPage: 1, //当前页数 ，默认为1
				dian: 0
			};
		},
		computed: {
			//写在computed里面，每次currentPage发生改变就会执行一次
			afterChangeData() {
				let start = (this.currentPage - 1) * this.pageNum
				let end = this.currentPage * this.pageNum
				return this.productlist.slice(start, end)
			}
		},
		created() {
			this.routerId = this.$route.params.id;
			console.log(this.routerId)
			this.getHTTP()
		},

		methods: {
			toPage(id) {
				this.$router.push({
					path: '/ShoppingDeilt/' + id
				})
				// this.$router.push({
				//   name: 'ShoppingDeilt',
				//   params: {id:2,aid:id}
				// })
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val
			},
			clickMenu(index, productType) {
				this.currentPage = 1
				this.$api.article.GetProductList({
					customerId: 117
				}).then(res => {
					var msg = res.data.result
					this.productlist = msg
					this.condition = []
					this.isSize = index
					if (this.isSize != 0) {
						for (var i in this.productlist) {
							if (this.productlist[i].productType == productType) {
								this.condition.push(this.productlist[i])
							}
						}
						this.productlist = this.condition
						console.log(this.productlist)
						this.count = this.productlist.length
					} else {
						this.dian = 1
						this.getHTTP()
					}
				})
			},
			getHTTP() {
				const params = {
					customerId: 117
				}
				this.$api.article.GetProductList(params).then(res => {
					var msg = res.data.result
					this.productlist = msg
					this.count = this.productlist.length

					var map = {},
						dest = [{
							name: '全部'
						}];
					for (var i in msg) {
						var ai = msg[i];
						if (!map[ai.productType]) {
							dest.push({
								productType: ai.productType,
								name: ai.typeName,
								data: [ai]
							});
							map[ai.productType] = ai;
						} else {
							for (var j = 0; j < dest.length; j++) {
								var dj = dest[j];
								if (dj.productType == ai.productType) {
									dj.data.push(ai);
									break;
								}
							}
						}
					}
					console.log('----整理好的产品类型')
					console.log(dest)
					this.productmenu = dest
					if (this.dian == 0) {
						for (var i in dest) {
							if (this.routerId == dest[i].productType) {
								this.isSize = i
								this.productlist = dest[i].data
								this.count = this.productlist.length
							}
						}
					}

				})

			}
		},
	};
</script>

<style lang="less" scoped>
	.shop {
		position: relative;
		width: 100vw;
		min-width: 1200px;
		height: auto;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: #F5F5F5;

		.shop-count {
			width: 100%;
			height: auto;
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.shop-body {
				width: 1200px;
				height: auto;
				position: relative;
				box-sizing: border-box;
				background-color: #ffffff;
				margin: 20px 0;

				.shop-body-menu {
					width: 90%;
					height: auto;
					margin: 0 auto;
					display: flex;
					padding: 24px 0 0 0;
					padding-bottom: calc(24px - 12px);

					.shop-body-menu-left {
						position: relative;
						width: 120px;
						height: auto;
						padding: 7px 9px;
					}

					.box {
						position: relative;
						box-sizing: border-box;
						display: flex;
						flex-wrap: wrap;

						.shop-body-menu-right {
							box-sizing: border-box;
							position: relative;
							width: 19%;
							margin-right: 1%;

							font-size: 14px;
							font-family: MicrosoftYaHei-Regular, MicrosoftYaHei;
							font-weight: 400;
							color: #666666;

							cursor: pointer;
							text-align: center;
							background-color: #F5F5F5;
							padding: 7px 9px;
							margin-bottom: 12px;

							div {
								width: 100%;
								padding: 7px 9px;
								display: inline-block;
							}
						}

						.bg {
							// background: #D7DB81;
							background: #77A760;
							color: #FFFFFF;

						}
					}


				}


			}


			.body {
				width: 1200px;
				height: auto;
				position: relative;
				box-sizing: border-box;
				background-color: #ffffff;
				display: flex;
				flex-wrap: wrap;

				.body-list {
					width: 300px;
					height: 432px;
					border: 1px solid #F3F3F3;
					box-sizing: border-box;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					cursor: pointer;

					.body-list-img {
						width: 100%;
						height: auto;
						box-sizing: border-box;
						text-align: center;

						img {
							max-width: 260px;
							max-height: 260px;
						}
					}

					.body-list-font {
						position: relative;
						width: 260px;
						height: auto;
						margin: 0 auto;

						.body-list-font-title {
							font-size: 16px;
							font-family: MicrosoftYaHei-Regular, MicrosoftYaHei;
							font-weight: 400;
							color: #242424;
							white-space: nowrap;
							/*文本溢出显示省略号*/
							text-overflow: ellipsis;
							/*溢出的部分隐藏*/
							overflow: hidden;
						}

						.body-list-font-count {
							margin-top: 12px;
							font-size: 14px;
							font-family: MicrosoftYaHei-Regular, MicrosoftYaHei;
							font-weight: 400;
							color: #666666;

							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;


						}
					}

					.body-list-show {
						margin: 0 auto;
						position: relative;
						width: 260px;
						height: auto;
						display: flex;
						align-items: center;
						justify-content: space-between;

						.body-list-show-price {
							font-size: 20px;
							font-family: MicrosoftYaHei-Regular, MicrosoftYaHei;
							font-weight: 400;
							color: #933D12;
						}

						.body-list-show-deailt {
							font-size: 14px;
							font-family: MicrosoftYaHei-Regular, MicrosoftYaHei;
							font-weight: 400;
							color: #7A2900;
						}
					}
				}
			}

			.shop-pagination {
				width: 1200px;
				height: auto;
				position: relative;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 50px 0 80px;
			}
		}
	}

	.bg {
		background: #D7DB81;
	}

	::v-deep {

		/*不带背景的分页按钮背景色begin*/
		.el-pager li {
			color: #666666;
		}

		.el-pager li.active {
			color: #7A2900;
			cursor: default;
		}

		.el-pagination .el-pager li:hover {
			color: #7A2900;
		}

		.el-pagination .el-pager li:not(.disabled):hover {
			color: #666666;
		}
	}

	/*不带背景的分页按钮背景色end*/
</style>
