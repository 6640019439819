/**
 * article模块接口列表
 *  this.$api.article.articleDetail(id, {
            api: 123
        }).then(res=> {
            // 执行某些操作
        })
 */

import base from './base'; // 导入接口域名列表
import axios from './http.js'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const article = {
    GetProductList(params){
      return axios.get(`${base.sq}/api/VipProduct/GetProductList`,{
        params:params
      });
    },
    GetProductDetail(params){
      return axios.get(`${base.sq}/api/VipProduct/GetProductDetail`,{
        params:params
      });
    },
    GetAppleShareQrcode(params){
      return axios.get(`${base.sq}/api/CakeProducts/GetAppleShareQrcode`,{
		  params:params
	  });
    },
    GetCakeCommandWeb(params){
      return axios.get(`${base.sq}/api/CakeCommands/GetCakeCommandWeb`);
    },









    // 新闻列表
    articleList () {
        return axios.get(`${base.sq}/topics`);
    },
    // 新闻详情,演示
    articleDetail (id, params) {
        return axios.get(`${base.sq}/topic/${id}`, {
            params: params
        });
    },
    // post提交
    login (params) {
        return axios.post(`${base.sq}/accesstoken`, qs.stringify(params));
    }
    // 其他接口…………
}

export default article;
