import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Shopping from '../views/Shopping.vue'
import ShoppingDeilt from '../views/ShoppingDeilt.vue'
import distribution from '../views/distribution.vue'
import enterprise from '../views/enterprise.vue'
import store from '../views/store.vue'
import about from '../views/about.vue'
import Mobile from '../views/Mobile.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/Home',
		name: 'Home',
		component: Home,
	},
	{
		path: '/Shopping/:id',
		name: 'Shopping',
		component: Shopping,
	},
	{
		path: '/ShoppingDeilt/:id',
		name: 'ShoppingDeilt',
		component: ShoppingDeilt,
	},


	{
		path: '/about',
		name: 'about',
		component: about
	},
	{
		path: '/store',
		name: 'store',
		component: store
	},
	{
		path: '/distribution',
		name: 'distribution',
		component: distribution
	},
	{
		path: '/enterprise',
		name: 'enterprise',
		component: enterprise
	},
	{
		path: '/Mobile',
		name: 'Mobile',
		component: Mobile
	},
	{
		path: '*',
		name: 'Home',
		component: Home,
	},
	
]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		return {
			x: 0,
			y: 0
		}
	}
})

export default router
