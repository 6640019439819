import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    page: 0,
  },
  mutations: {
    selece_page(state, page) {
      state.page = page
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState({
        storage: window.sessionStorage
    })]
})
