<template>
	<div class="shopdeilt">
		<div class="shopdeilt-count">
			<div class="shopdeilt-message">
				<div class="shopdeilt-message-top">
					<div class="left">
						<!-- <img src="../../dist/static/img/sr_pic1@2x.9b440c3.png" /> -->
						<div class="left-div">
							<img :src="picurlOne" />
						</div>
						<div class="shopdeilt-message-bottom">
							<div class="img" v-for="(item,index) in picUrl" :key="index" @click="optPic(index)">
								<img :src="item" />
							</div>
						</div>
					</div>
					<div class="right">
						<div class="right-div">
							<div class="right-title">{{data.title}}</div>
							<div class="right-font">
								{{data.intro}}
							</div>
							<div class="right-specs">
								<div class="top">规格</div>
								<div class="bottom">
									<div class="bottom-size" :class="{ bg: index == isSize }"
										v-for="(item,index) in data.priceInfo" :key="index"
										@click="clickPriceInfo(index,item.price)">
										{{item.info}}
									</div>
								</div>
							</div>
							<div class="right-price">
								<div class="top">价格</div>
								<div class="bottom"><span>￥</span> {{price}}</div>
							</div>
						</div>
						<div class="right-pay">
							<el-popover placement="right" width="200" trigger="click">
								<div class="cc">
									<div class="cc_div">
										<div>{{ wx_code == "" ? "加载中" : "请使用微信扫一扫" }}</div>
										<div>{{ wx_code == "" ? "" : "识别二维码预定" }}</div>
									</div>
									<img v-show="wx_code != ''" class="cc_img" :src="wx_code" />
								</div>
								<div slot="reference" class="btn-buy" @click="clickBuy">
									<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/btn_sm@2x.png" />
								</div>
							</el-popover>
						</div>
					</div>
				</div>
				<!-- <div class="shopdeilt-message-bottom">
          <div class="img" v-for="(item,index) in picUrl" :key="index" @click="optPic(index)">
            <img :src="item" />
          </div>
        </div> -->
			</div>
		</div>

		<div class="shopdeilt-richtext">
			<div class="richtext-Exhibition">
				<div class="richtext-video" v-if="data.video != '' && data.video != null " >
					<video  :src="data.video" controls autoplay></video>
				</div>
				<div class="richtext" v-html="data.content"></div>
			</div>
		</div>


		<div class="shopdeilt-tip">
			<div class="tip-pic">
				<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/xq_wz@2x.png" />
			</div>
		</div>
	</div>
</template>

<script>
	import axios from "axios";
	export default {
		data() {
			return {
				id: 0,
				data: [],
				isSize: 0,
				price: 0,
				picUrl: [],
				picurlOne: '',
				wx_code: ''
			};
		},
		created() {
			this.id = this.$route.params.id;
			console.log(this.id)
			const params = {
				pId: this.id
				// pId: 876
			}
			this.$api.article.GetProductDetail(params).then(res => {
				this.data = res.data.result
				console.log(this.data)
				this.price = this.data.priceInfo[0].price
				this.picurlOne = this.data.picUrl[0]
				this.picUrl = this.data.picUrl
				console.log(this.data.video)
			})


			// this.$axios.post('api/CakeProducts/GetAppleShareQrcode',{
			//     page: 'pages/coupon/coupon',
			//     scene: this.id + ',1,0',
			//   }).then(res => {
			//    //请求的数据存储在res.data 中
			// })
		},

		methods: {
			clickPriceInfo(index, price) {
				this.isSize = index
				console.log(this.isSize)
				this.price = price
				console.log(price)
			},
			optPic(index) {
				this.picurlOne = this.picUrl[index]
			},
			clickBuy() {
				let params = {
					page: 'pages/coupon/coupon',
					scene: this.id,
				}
				this.$api.article.GetAppleShareQrcode(params).then(res => {
					console.log(res.data);
					this.wx_code = res.data
				})
				// this.$axios.post('api/CakeProducts/GetAppleShareQrcode', params)
				// 	.then(function(response) {
				// 		console.log(response.data);
				// 		this.wx_code = response.data
				// 	}.bind(this))
				// 	.catch(function(error) {
				// 		console.log(error);
				// 	});
			}

		},
	};
</script>
<style>
	.richtext img{
		width: 100%;
		height: auto;
	}
</style>

<style scoped lang="less">
	.shopdeilt {
		position: relative;
		width: 100vw;
		min-width: 1200px;
		height: auto;
		margin: 50px auto 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		// background-color: #F5F5F5;
		background-color: #FFFFFF;

		.shopdeilt-richtext {
			width: 100%;
			height: auto;
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			background-color: #FBFCF7;

			.richtext-Exhibition {
				width: 1200px;
				height: auto;
				position: relative;
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin-top: 40px;

				.richtext {
					width: 750px;
					height: auto;
					// display: flex;
					// align-items: center;
					// justify-content: center;
				}

				.richtext-video {
					width: 750px;
					// height: auto;
					height: 400px;

					video {
						width: 100%;
						// height: auto;
						height: 400px;
					}
				}
			}
		}


		.shopdeilt-tip {
			width: 100%;
			height: auto;
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			background-color: #ffffff;
			padding-top: 60px;

			.tip-pic {
				width: 1200px;
				height: auto;
				position: relative;
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-around;

				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
		}


		.shopdeilt-count {
			width: 100%;
			height: auto;
			min-height: 631px;
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			// background: linear-gradient(180deg, rgba(215, 219, 129, 0) 0%, #F8F9F0 100%);
			.shopdeilt-message {
				width: 1200px;
				// height: 763px;
				// height: 462px;
				height: auto;
				min-height: 547px;
				position: relative;
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: space-around;

				.shopdeilt-message-top {
					width: 100%;
					height: auto;
					display: flex;
					align-items: flex-start;
					justify-content: space-between;

					.left {
						width: 680px;
						height: auto;

						display: flex;
						flex-direction: column;

						.left-div {
							width: 680px;
							height: 462px;
							// width: 100%;
							// height: 100%;
							// overflow: hidden;
							display: flex;
							align-items: center;
							justify-content: center;

							// background: pink;
							img {
								// width: 680px;
								height: 462px;
							}
						}


						.shopdeilt-message-bottom {
							width: 100%;
							height: auto;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-top: 11px;

							.img {
								width: 66px;
								height: 66px;
								margin-right: 20px;

								img {
									// max-width: 100%;
									// max-height: 100%;
									width: 100%;
									height: 100%;
									border: 1px solid #D7DB81;
									cursor: pointer;
								}
							}
						}
					}

					.right {
						border: 0.5px solid #e3e4e5;
						// width: 437px;
						// width: 437px;
						width: 377px;
						height: auto;
						min-height: 508px;
						// height: 100%;
						// height: calc(100% - 60px);
						// height: auto;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						padding: 30px 30px 30px 30px;


						.right-div {
							display: flex;
							flex-direction: column;
							width: 100%;
							height: auto;

							.right-title {
								font-size: 24px;
								font-family: MicrosoftYaHei-Regular, MicrosoftYaHei;
								font-weight: 400;
								color: #242424;
								line-height: 40px;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 3;
								-webkit-box-orient: vertical;
							}

							.right-font {
								margin-top: 30px;
								font-size: 14px;
								font-family: MicrosoftYaHei-Regular, MicrosoftYaHei;
								font-weight: 400;
								color: #666666;
								// border-bottom: 1px dashed #CCCCCC;
								border-bottom: 1px dashed #e3e4e5;
								padding-bottom: 20px;

								line-height: 26px;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 4;
								-webkit-box-orient: vertical;

							}

							.right-specs {
								display: flex;
								flex-direction: column;
								margin-top: 20px;

								.top {
									margin-bottom: 20px;
									height: 14px;
									font-size: 14px;
									font-family: MicrosoftYaHei-Regular, MicrosoftYaHei;
									font-weight: 400;
									color: #242424;
								}

								.bottom {
									display: flex;
									align-items: center;
									flex-wrap: wrap;
									box-sizing: border-box;
									position: relative;

									.bottom-size {
										// width: 88px;
										padding: 0 18px;
										height: 32px;
										border-radius: 0px 0px 0px 0px;
										opacity: 1;
										display: flex;
										align-items: center;
										justify-content: center;
										margin-right: 6px;
										margin-bottom: 10px;
										cursor: pointer;
										background-color: #e3e4e5;


										font-size: 14px;
										font-family: MicrosoftYaHei-Regular, MicrosoftYaHei;
										font-weight: 400;
										color: #242424;
									}

									.bg {
										// background: #D7DB81;
										background: #6D9F52;
										color: #FFFFFF;
										
									}
								}
							}

							.right-price {
								display: flex;
								flex-direction: column;
								margin-top: 20px;

								.top {
									font-family: MicrosoftYaHei-Regular, MicrosoftYaHei;
									font-weight: 400;
									color: #242424;
									font-size: 14px;
								}

								.bottom {
									font-size: 32px;
									font-family: DIN-Bold, DIN;
									font-weight: bold;
									color: #242424;

									span {
										font-size: 28px;
									}
								}
							}

						}











						.right-pay {
							position: relative;
							width: 376px;
							height: 52px;
							cursor: pointer;

							// margin-bottom: 37px;
							img {
								max-width: 100%;
								max-height: 100%;
							}
						}
					}
				}

				// .shopdeilt-message-bottom {
				//   width: 50%;
				//   height: auto;
				//   display: flex;
				//   align-items: center;

				//   .img {
				//     width: 90px;
				//     height: 63px;
				//     margin-right: 20px;

				//     img {
				//       // max-width: 100%;
				//       // max-height: 100%;
				//       width: 100%;
				//       height: 100%;
				//       border: 1px solid #D7DB81;
				//       cursor: pointer;
				//     }
				//   }
				// }
			}
		}
	}

	.right-pay-img {
		max-width: 100%;
		max-height: 100%;
	}

	.cc {
		display: flex;
		flex-direction: column;
		width: 200px;
		height: 225px;
		align-items: center;

		.cc_div {
			font-size: 14px;
			line-height: 24px;
			color: #333333;
			text-align: center;
			margin-top: 10px;
			opacity: 1;
		}

		.cc_img {
			margin-top: 10px;
			width: 150px;
			height: 150px
		}
	}

	.btn-buy {
		width: 376px;
		height: 52px;

		img {
			width: 100%;
			height: 100%;
		}
	}
</style>
