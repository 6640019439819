<template>
	<div class="total">
		<div class="total_banner">
			<!-- <img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/sy_banner.png" /> -->

			<img v-if="banner.length == 1" :src="banner[0].picurl" />

			<div v-else class="swiper-container backgroundfff">
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="(item,index) in banner" :key="index">
						<img :src="item.picurl" />
					</div>
				</div>
			</div>


		</div>
		<!-- 热卖 -->
		<div class="product-bar">
			<img class="bar-db" src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/rm_bg_H.png" />
			<div class="bar-count">
				<div class="product-bar-title">
					<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/rm_wz@2x.png" />
				</div>
				<div class="product-bar-count">
					<div class="product-bar-count-box" @click="topage(38)">
						<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/rm_pic1@2x.png" />
					</div>
					<div class="product-bar-count-box" @click="topage(47)">
						<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/rm_pic2@2x.png" />
					</div>
					<div class="product-bar-count-box" @click="topage(48)">
						<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/rm_pic3@2x.png" />
					</div>
					<div class="product-bar-count-box" @click="topage(43)">
						<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/rm_pic4@2x.png" />
						<!-- <img src="../../static/rm_pic1@2x.png" />
            <div class="product-bar-count-box_title">日时年轮蛋糕</div> -->
					</div>
				</div>
			</div>

		</div>

		<!-- 甄选食材 -->
		<!-- <div class="product-bar">
			<img class="bar-db" src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/zx_bg@2x.png" />
			<div class="bar-count1">
				<div class="bar-count1-60">
					<div class="product-bar-count1">
						<div class="count1_title">
							<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/zxsc_wz@2x.png" />
						</div>
						<div class="count1_img">
							<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/zxsc_pic@2x.png" />
						</div>
					</div>
				</div>
			</div>
		</div> -->

		<div class="product-bar">
			<img class="bar-db" src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/lmcp@2x.png" />
		</div>



		<!-- 生日蛋糕 -->
		<div class="product-bar" style="height: 50rem;">
			<div class="bar-count2">
				<div class="product-bar-count2">
					<div class="product-bar-count-box" v-for="(item,index) in listURL" :key='index'
						@click="goDeitl(item.id)">
						<div class="product-bar-count-box-img">
							<img :src="item.picUrl" />
						</div>
						<div class="product-bar-count-box-font">
							{{item.title}}
						</div>
					</div>
					<div class="count1_title" >
						<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/sr_wz@2x.png" />
					</div>
				</div>
			</div>
		</div>


		<div class="product-bar">
			<img class="bar-db" src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/zxsc@2x.png" />
		</div>


		<div class="product-bar" @click="pageAbout()">
			<!-- <img class="bar-db" style="height: 37.5rem;" src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/img_hz@2x.png" /> -->
			<img class="bar-db1" src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/img_hz@2x.png" />
		</div>

		<div class="Mask">
			<img class="Mask1" v-if="show" @click="dian(0)" src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/ewm_1%402x.png" />
			<img class="Mask2" v-if="!show" @click="dian(1)" src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/ewm_ico%402x.png" />
		</div>

		<!-- <div class="total_banner">
      <img src="../../static/img_hz@2x.png" />
    </div> -->
	</div>
</template>

<script>
	import Swiper from "swiper"
	export default {
		// name: "Home",
		data() {
			return {
				show: true,
				productlist: [],
				listURL: [], //生日蛋糕照片
				banner: [{
					picurl: 'http://49.4.54.61:10093//UpLoadPic/command/202203/c637820107489261209.png'
				}, {
					picurl: 'http://49.4.54.61:10093//UpLoadPic/command/202203/c637820107489261209.png'

				}] //banner轮播
			};
		},
		created() {
			this.getHTTP()
			this.lunbo()
		},
		mounted() {
			this.myswiper()
		},
		methods: {
			dian(){
				this.show = !this.show
			},
			lunbo() {
				this.$api.article.GetCakeCommandWeb().then(res => {
					this.banner = res.data
				})
			},


			myswiper: function() {
				var swiper = new Swiper('.swiper-container', {
					// direction: 'vertical', // 垂直切换选项
					// loop: true, // 循环模式选项
					autoplay: true
					// pagination: {
					//   el: '.swiper-pagination',

					// },
				});
			},
			topage(e) {
				this.$router.push({
					path: '/Shopping/' + e
				})
			},
			goDeitl(e) {
				this.$router.push({
					path: '/ShoppingDeilt/' + e
				})
			},
			pageAbout() {
				this.$store.commit("selece_page", 4);
				this.$router.push({
					path: '/about'
				})
			},
			getHTTP() {
				const params = {
					customerId: 117
				}
				this.$api.article.GetProductList(params).then(res => {
					var msg = res.data.result
					var map = {},
						dest = [];
					for (var i in msg) {
						var ai = msg[i];
						if (!map[ai.productType]) {
							dest.push({
								productType: ai.productType,
								name: ai.typeName,
								data: [ai]
							});
							map[ai.productType] = ai;
						} else {
							for (var j = 0; j < dest.length; j++) {
								var dj = dest[j];
								if (dj.productType == ai.productType) {
									dj.data.push(ai);
									break;
								}
							}
						}
					}
					this.productlist = dest
					console.log(dest)
					for (var i in dest) {
						if (dest[i].productType == 38) {
							this.listURL = dest[i].data.slice(0, 3)
						}
					}
					console.log(this.listURL)
				})

			}
		},
	};
</script>
<style lang="less" scoped>
	@import url("../../node_modules/swiper/css/swiper.css");

	.Mask{
		position: fixed;
		bottom: 0;
		right: 0;
		// width: 260px;
		// height: 252px;
		z-index: 2;
		.Mask1{
			width: 260px;
			height: 252px;
		}
		.Mask2{
			width: 68px;
			height: 111px;
		}
	}

	.swiper-container {
		width: 100%;
		height: auto;
	}

	.total {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		// width: 100%;
		width: 100vw;
		min-width: 1200px;
		height: auto;
		margin: 0 auto;

		.total_banner {
			width: 100%;
			height: auto;
			position: relative;
			text-align: center;

			img {
				max-width: 100%;
				max-height: 50rem;
			}
		}

		.product-bar {
			width: 100%;
			// width: 1200px;
			height: auto;
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;

			.bar-db {
				z-index: 0;
				max-width: 100%;
				max-height: 50rem;
			}

			.bar-db1 {
				z-index: 0;
				max-width: 100%;
				max-height: 37.5rem;
			}

			.bar-count {
				position: absolute;
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-around;

				.product-bar-title {
					width: 13.4375rem;
					height: 8.75rem;

					img {
						max-width: 100%;
						max-height: 100%;
					}
				}

				.product-bar-count {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 60%;
					// justify-content: center;
					box-sizing: border-box;


					div:nth-child(2),
					div:nth-child(4) {
						bottom: 6.25rem;
					}

					.product-bar-count-box {
						position: relative;
						width: 18.5rem;
						// height: 18.75rem;
						height: auto;
						cursor: pointer;

						// margin-right: 0.75rem;
						.product-bar-count-box_title {
							position: absolute;
							top: 2.1875rem;
							// right: -15px;
							right: 0;

							font-size: 1.5625rem;
							font-family: Source Han Sans CN-Bold, Source Han Sans CN;
							font-weight: bold;
							color: #333003;
							width: 0.9375rem;
							height: 10.9375rem;
							border-radius: 0.75rem;
							margin: 0 auto;
							line-height: 2.1875rem;
							background-color: #D7DB81;
							padding: 0 1.375rem;
							display: flex;
							align-items: center;
							justify-content: center;
						}

						img {
							position: relative;
							max-width: 100%;
							max-height: 100%;
						}
					}
				}
			}

			.bar-count1 {
				position: absolute;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				.bar-count1-60 {
					width: 100%;
					box-sizing: border-box;
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;

					.product-bar-title {
						width: 13.4375rem;
						height: 8.75rem;

						img {
							max-width: 100%;
							max-height: 100%;
						}
					}

					.product-bar-count {
						position: relative;
						display: flex;
						align-items: center;
						justify-content: center;
						width: 60%;
						// justify-content: center;
						box-sizing: border-box;


						div:nth-child(2),
						div:nth-child(4) {
							bottom: 6.25rem;
						}

						.product-bar-count-box {
							position: relative;
							width: 18.5rem;
							height: 18.75rem;
							margin-right: 0.75rem;

							.product-bar-count-box_title {
								position: absolute;
								top: 2.1875rem;
								// right: -15px;
								right: 0;

								font-size: 1.5625rem;
								font-family: Source Han Sans CN-Bold, Source Han Sans CN;
								font-weight: bold;
								color: #333003;
								width: 0.9375rem;
								height: 10.9375rem;
								border-radius: 0.75rem;
								margin: 0 auto;
								line-height: 2.1875rem;
								background-color: #D7DB81;
								padding: 0 1.375rem;
								display: flex;
								align-items: center;
								justify-content: center;
							}

							img {
								position: relative;
								max-width: 100%;
								max-height: 100%;
							}
						}
					}

				}


			}

			.bar-bottom {
				width: 15.3125rem;
				height: 3.875rem;
				z-index: 99;
				position: absolute;
				bottom: 2.875rem;
			}

			.bar-count2 {
				position: absolute;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				.product-bar-count2 {
					position: relative;
					width: 60%;
					display: flex;
					align-items: flex-start;
					justify-content: center;
					left: -3.75rem;
					box-sizing: border-box;

					.count1_title {
						position: relative;
						// position: absolute;
						// right: -350px;
						// top: 2.5rem;
						// width: 15.5625rem;
						// height: 22.5rem;
						// height: auto;
						height: 431px;
						// left: 7.5625rem;
						    position: absolute;
						    right: -186px;

						img {
							position: relative;

							max-width: 100%;
							max-height: 100%;
						}
					}

					.product-bar-count-box75 {
						margin: 0 4.6875rem;
					}

					.product-bar-count-box {
						position: relative;
						width: 18.75rem;
						height: 17.6875rem;
						margin-right: 75px;
						cursor: pointer;

						.product-bar-count-box-img {
							position: relative;
							width: 100%;
							height: auto;

							img {
								max-width: 100%;
								max-height: 100%;
								border-radius: 20px;
							}
						}

						.product-bar-count-box-font {
							padding: 0 0.625rem;
							margin-top: 1.25rem;
							font-size: 1.125rem;
							font-family: Microsoft YaHei-Regular, Microsoft YaHei;
							font-weight: 400;
							color: #363636;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
						}

					}
				}
			}




			.count1_title {
				// position: absolute;
				// left: -14.25rem;
				// bottom: 0;
				width: 15.5625rem;
				height: 22.5rem;
				

				// position: relative;
				img {
					position: relative;

					max-width: 100%;
					max-height: 100%;
				}
			}

			.count1_img {
				width: 1198px;
				height: 412px;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					max-width: 100%;
					max-height: 100%;
				}
			}


			.product-bar-count1 {
				position: relative;
				display: flex;
				// width: 1200px;
				align-items: center;
				justify-content: center;
				width: 60%;
				box-sizing: border-box;



				.product-bar-count-box {
					position: relative;
					width: 18.75rem;
					// height: 25.625rem;
					height: auto;

					.product-bar-count-box_title {
						position: absolute;
						top: 0;
						// right: -15px;
						right: 0;

						background: #000000;
						border-radius: 0;
						opacity: 0.7;
						font-size: 1.5rem;
						font-family: MicrosoftYaHei-Regular, MicrosoftYaHei;
						font-weight: 400;
						color: #FFFFFF;

						width: 0.9375rem;
						height: 10.9375rem;
						margin: 0 auto;
						line-height: 2.1875rem;
						padding: 0 1.375rem;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					img {
						position: relative;
						max-width: 100%;
						max-height: 100%;
					}
				}
			}


			.product-bar-count3 {
				position: absolute;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				.count3-div {
					position: relative;
					box-sizing: border-box;
					width: 60%;
					height: auto;
					display: flex;
					align-items: center;
					justify-content: center;

					.count3-img {
						width: 249px;
						height: auto;
						position: absolute;
						left: -15rem;

						img {
							max-width: 100%;
							max-height: 100%;
						}
					}

					.count3-left {
						position: relative;
						box-sizing: border-box;
						width: 33.5rem;
						// height: 28.625rem;
						height: auto;
						margin-right: 3.125rem;

						img {
							max-width: 100%;
							max-height: 100%;
						}
					}

					.count3-right {
						position: relative;
						box-sizing: border-box;
						width: 33.5rem;
						height: auto;
						// height: 28.625rem;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: space-around;

						.count3-right-title {
							width: 12.125rem;
							height: 2.9375rem;
							position: relative;
							// margin-bottom: 23px;

							img {
								max-width: 100%;
								max-height: 100%;
							}
						}

						.count3-right-font {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 100%;
							height: 30px;
							margin: 15px 0 20px;

							div {
								font-size: 30px;
								font-family: Source Han Sans CN-Regular, Source Han Sans CN;
								font-weight: 400;
								color: #FFEFE9;
								line-height: 0px;
								margin: 0 31px;
							}

							img {
								max-width: 25px;
								max-height: 12px;
							}
						}

						.count3-right-pic {
							width: 100%;
							height: auto;

							img {
								width: 48%;
								height: 48%;
								float: left;
							}
						}
					}
				}

			}

		}
	}
</style>
