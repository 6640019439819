import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import less from 'less'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import api from './api' // 导入api接口

import axios from 'axios'
Vue.prototype.$axios = axios;
axios.defaults.baseURL = '/api'
// axios.defaults.baseURL = 'https://api.puretty.cn/'
// axios.defaults.baseURL = process.env.BASE_API;

Vue.prototype.$api = api; // 将api挂载到vue的原型上
Vue.use(less)
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
