<template>
  <div class="header-total">
    <div class="header-content">
      <div class="header-div">
        <div class="hdeader-title" v-for="(item,index) in menu" :key="item.id" @click="toPage(item.id,index)">
          <img v-if="item.id == 6" src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/nav_logo.png" />
          <span class="color" :class="{ color_chooes: item.id == $store.state.page }">{{item.name}}</span>
          <span class="color" :class="{ color_chooes: item.id == $store.state.page }">{{item.ename}}</span>
          <span v-if="item.id == $store.state.page" class="i"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isactive:0,
        menu: [{
            id: 0,
            name: '首页',
            ename: 'Home',
          },
          {
            id: 1,
            name: '蒲缇商城',
            ename: 'Shopping Mall',
          },
          {
            id: 2,
            name: '配送服务',
            ename: 'Distribution',
          },
          {
            id: 6,
            url: 'https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/nav_logo.png'
          },
          {
            id: 3,
            name: '企业专区',
            ename: 'Enterprise',
          },
          {
            id: 4,
            name: '关于我们',
            ename: 'About  us',
          },
          {
            id: 5,
            name: '门店',
            ename: 'Store',
          },

        ]
      };
    },

    methods: {
      toPage(id,index){
        this.$store.commit("selece_page", id);
        // this.isactive = id
        console.log(this.$store.state.page)
         // this.isactive = this.$store.state.page
        if(this.$store.state.page == 5){
          this.$router.push('/store')
        }else if (this.$store.state.page == 0) {
          this.$router.push('/Home')
        }else if(this.$store.state.page == 4){
          this.$router.push('/about')
        }else if(this.$store.state.page == 2){
          this.$router.push('/distribution')
        }else if(this.$store.state.page == 3){
          this.$router.push('/enterprise')
        }else if(this.$store.state.page == 1){
          this.$router.push('/Shopping/0')
        }
      }
    },
  };
</script>

<style lang="less" scoped>
  .header-total {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    min-width: 1200px;
    background: #AA8477;
    height: 120px;
    opacity: 1;

    .header-content {
      display: flex;
      justify-content: center;
      width: 100%;

      .header-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 800px;
        // height: 40px;
        position: relative;

        .hdeader-title {
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 6.3125rem;
            height: 4rem;
          }

          span:nth-child(1) {
            font-size: 18px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
          }

          span:nth-child(2) {
            font-size: 12px;
            font-family: Source Han Sans CN-ExtraLight, Source Han Sans CN;
            font-weight: 200;
          }

          .i {
            width: 3.75rem;
            height: 3px;
            background: #EACDB9;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
            position: absolute;
            bottom: -1.5625rem;
          }
        }
      }
    }
  }

  .color{
    color: #FFFFFF;
	opacity: 0.9;
  }
  .color_chooes{
    color: #EACDB9;
  }
</style>
