<template>
	<div id="app">
		<div>
			<img :src="image_url" class="image" @click="clickImage">
		</div>
	</div>
</template>

<script>
	export default {
		// name: "Home",
		data() {
			return {
				app: 0, //0蒲缇
				url_scheme_array: [
					"weixin://dl/business/?t=RJjXXvqHSVu"
				],
				image_url: "https://jiaqi.obs.cn-north-1.myhuaweicloud.com/active/img_puti.png",
			};
		},
		created() {},
		mounted() {},
		methods: {
			clickImage() {
				console.log("click");
				window.location.href = this.url_scheme_array[this.app];
			},
		},
	};
</script>

<style>
	html,
	body,
	ul,
	li {
		margin: 0rem;
		padding: 0rem;
	}

	.image {
		width: 100%;
		height: auto;
	}
</style>
