<template>
	<div id="app" class="app">
		<HomeHead v-if="this.$route.path != '/Mobile'"></HomeHead>
		<router-view />
		<HomeFoot v-if="this.$route.path != '/Mobile'"></HomeFoot>
	</div>
</template>

<script>
	import HomeHead from "./components/Home_Header.vue";
	import HomeFoot from "./components/Home_Foot.vue";

	export default {
		components: {
			HomeHead,
			HomeFoot
		},
		name: 'App',
		mounted() {
			if (this._isMobile()) {
				// alert("手机端");
				console.log("手机端")
				console.log(this.$route.path)
				this.$router.replace('/Mobile');
			} else {
				// alert("pc端");
				console.log("pc端")
				this.$router.replace('/Home');
			}
		},
		methods: {
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
					)
				return flag;
			}
		}
	}
</script>

<style>
	body {
		margin: 0px;
		padding: 0px;
		overflow-x: hidden;

		.app {
			display: flex;
			flex-direction: column;
			background-color: #F5F5F5;
			width: 100vw;
			height: auto;
		}
	}
</style>
