<template>
	<div class="header-Foot">
		<div class="foot">
			<!-- <div class="foot-logo">
				<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/nav_logo.png" />
			</div> -->
			<div class="foot-count">
				<div class="foot-logo">
					<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/nav_logo.png" />
				</div>
				<div class="left">
					<div class="left-friendship">
						<div class="left-friendship-title">友情链接</div>
						<div class="left-friendship-font" @click="address(0)">辽宁大运通国际旅行社</div>
						<div class="left-friendship-font" @click="address(1)">辽宁康辉国际旅行社</div>
						<div class="left-friendship-font" @click="address(2)">辽宁甜程旅行网</div>
					</div>
					<div class="left-friendship" style="margin-left: 100px;">
						<div class="left-friendship-title">联系方式</div>
						<div class="left-friendship-font">客服热线：024-22815399（9：00-18：00）</div>
						<div class="left-friendship-font">Email：putihonghao@163.com</div>
					</div>
				</div>
				<div class="right">
					<el-popover placement="right" width="200" trigger="click" style="margin-right: 50px;">
						<div class="cc">
							<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/ewm.jpg" />
						</div>
						<div slot="reference" class="btn-buy">
							<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/d_ico_wx@2x.png" />
						</div>
					</el-popover>
					<!-- <img style="margin-right: 50px;" src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/d_ico_wx@2x.png" /> -->
					<img style="margin-right: 50px;"
						src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/d_ico_wb@2x.png" @click="pageWB()" />
					<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/puti/d_ico_tm@2x.png" @click="pageTM()" />
				</div>
			</div>
		</div>

		<div class="foot-record">
			<div class="foot-record-div">
				<div class="left">
					<div class="left-top" style="cursor: pointer;" @click="ahref()">网站备案信息：辽ICP备19006902号</div>
					<div class="left-bottom">统一社会信用代码：91810102313213269X</div>
				</div>
				<div class="left">
					<div class="left-top">总部地址：辽宁省沈阳市沈河区市府大路290号摩根凯利大厦A座一层</div>
					<div class="left-bottom">版权归属：辽宁蒲缇食品有限公司</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {
			pageWB() {
				window.open("https://weibo.com/puretty");
			},
			pageTM() {
				window.open(
					"https://putihongbei.tmall.com/?spm=a220o.1000855.w5001-18007731795.2.65206fa8vvoN9K&scene=taobao_shop"
					);
			},
			address(e) {
				if (e == 0) {
					window.open("http://www.dytyou.com")
				}else if(e == 1){
					window.open("http://www.lncct.com")
				}else if(e == 2){
					window.open("https://www.sweetour.com")
				}
			},
			ahref(){
				window.open('http://beian.miit.gov.cn/','_blank')
			}
		}
	}
</script>

<style lang="less" scoped>
	.header-Foot {
		width: 100vw;
		// height: 28.5rem;
		height: 16.5rem;
		min-width: 1200px;
		background: #AA8477;
		opacity: 1;
		position: relative;
		box-sizing: border-box;

		.foot {
			width: 100%;
			// height: 28.5rem;
			height: 16.5rem;
			min-width: 1200px;
			position: relative;
			box-sizing: border-box;
			display: flex;
			// flex-direction: column;
			align-items: center;
			justify-content: space-around;



			.foot-count {
				width: 1200px;
				height: auto;
				display: flex;
				align-items: center;
				justify-content: space-between;
				position: relative;
				box-sizing: border-box;

				.foot-logo {
					width: 98px;
					height: 61px;
					height: auto;
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						max-width: 100%;
						max-height: 100%;
					}
				}

				.left {
					// width: 50%;
					height: auto;
					position: relative;
					box-sizing: border-box;
					display: flex;
					align-items: flex-start;
					justify-content: space-between;

					.left-friendship {
						display: flex;
						flex-direction: column;
						// margin-right: 100px;



						.left-friendship-title {
							font-size: 18px;
							font-family: Microsoft YaHei-Regular, Microsoft YaHei;
							font-weight: 400;
							color: #FFFFFF;
							margin-bottom: 30px;
						}

						.left-friendship-font {
							font-size: 16px;
							font-family: Source Han Sans CN-Light, Source Han Sans CN;
							font-weight: 300;
							color: #FFFFFF;
							margin-bottom: 15px;
							opacity: 0.7;
							cursor: pointer;
						}
					}
				}

				.right {
					// width: 50%;
					height: auto;
					position: relative;
					box-sizing: border-box;
					display: flex;
					align-items: center;
					justify-content: flex-end;

					img {
						width: 55px;
						height: 55px;
						// margin-right: 50px;
						cursor: pointer;
					}
				}
			}
		}

		.foot-record {
			width: 100%;
			height: auto;
			min-width: 1200px;
			position: relative;
			box-sizing: border-box;
			background: #956859;
			opacity: 1;
			display: flex;
			align-items: center;
			justify-content: center;

			.foot-record-div {
				height: 66px;
				width: 1200px;
				position: relative;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.left {
					display: flex;
					flex-direction: column;
					justify-content: space-around;

					div {
						font-size: 12px;
						font-family: Microsoft YaHei-Regular, Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						opacity: 0.7;
					}
				}
			}
		}

	}

	.cc {
		display: flex;
		flex-direction: column;
		width: 200px;
		height: 225px;
		align-items: center;

		img {
			width: 200px;
			height: 225px;
		}
	}
	
</style>
